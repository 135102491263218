/*-------------------------------------------------------*/
/* Blog
/*-------------------------------------------------------*/
.single-post .content-wrapper {
  padding-top: 144px;

  @include bp-lg-down {
    padding-top: 20px;
  }
}

.content-wrapper--pt-large {
  padding-top: 100px;

  @include bp-lg-down {
    padding-top: 0;
  }
}

.entry {
  overflow: hidden;
  position: relative;
  margin-bottom: 48px;
  
  &__img {
    width: 100%;
  }

  &__date {
    position: absolute;
    top: 16px;
    left: 16px;
    background-color: #fff;
    color: $heading-color;
    padding: 12px 16px;
    border-radius: 5px;
    text-align: center;
    pointer-events: none;

    &-day {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 4px;
      display: block;
      line-height: 1;
    }

    &-month {
      @include uppercase;
      line-height: 1;
      display: block;
      font-size: 10px;
      font-weight: 500;
    }
  }

  &__title {
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 16px;
  }

  &__excerpt {
    margin-top: 20px;

    p {
      margin-bottom: 0;
    }
  }

}


/* Blog meta
-------------------------------------------------------*/
.entry__meta {
  li {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin-right: 18px;
    color: $meta-color;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    color: $heading-color;

    &:hover,
    &:focus {
      color: $main-color;
    }
  }

  span {
    color: $heading-color;
  }

  i {
    color: $main-color;
    font-size: 15px;
    display: inline-block;
    margin-right: 3px;
  }
}


/*-------------------------------------------------------*/
/* Pagination
/*-------------------------------------------------------*/
.pagination {
  text-align: center;
}

.page-numbers {
  font-size: 15px;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 38px;
  margin-right: 2px;
  border-radius: 5px;
  text-align: center;
  color: $text-color;
  background-color: #fff;
  border: 1px solid $border-color;
  vertical-align: middle;
  @include transition($all);

  &:not(span):hover {
    background-color: $main-color;
    border-color: transparent;
    color: #fff;
  }

  &.current {
    background-color: $main-color;
    color: #fff;
    border-color: transparent;
  }

  i {
    font-size: 12px;
  }
}


/*-------------------------------------------------------*/
/* Featured Image
/*-------------------------------------------------------*/
.blog-featured-img {
  padding: 152px 0;
  background-color: $bg-dark;

  .entry__meta li {
    font-size: 18px;
    margin-right: 6px;

    span {
      color: #fff;
    }

    &:after {
      content: '-';
      display: inline-block;
      margin-left: 6px;
    }

    &:last-child:after {
      display: none;
    }
  }

  .entry__meta-author {
    &-img {
      margin-right: 16px;
      border-radius: 50%;

      @include bp-sm-down {
        display: none;
      }
    }
  }

  @include bp-sm-down {
    padding: 80px 0;
  }
}



/*-------------------------------------------------------*/
/* Single Post
/*-------------------------------------------------------*/
.entry__article {
  overflow: hidden;

  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5,
  & > h6 {
    margin-top: 36px;
  }

  & > p,
  & > ul,
  & > ol {
    margin-bottom: 34px;
    font-size: 18px;
    line-height: 30px;
  }

  & > p:last-child {
    margin-bottom: 0;
  }

  p > a,
  ul:not(.entry__meta) a {
    color: $secondary-color;

    &:hover {
      color: $main-color;
    }
  }

  figure {
    margin-bottom: 24px;
  }

  ol, ul {
    padding-left: 18px;
    line-height: 30px;
  }

  ul {
    list-style: disc;
  }

  .entry__meta {
    padding-left: 0;
    margin-bottom: 20px;
    margin-top: -8px;
  }
}

figure {
  margin: 0;
}

figcaption {
  font-family: $body-font;
  font-size: 12px;
  font-style: italic;
  margin-top: 10px;
  color: $meta-color;
}

.entry__share {
  

  @include bp-lg-up {
    position: absolute;
    height: 100%;
    width: 50px;

    & + .entry__article {
       padding-left: 70px;
    }
  }

  @include bp-lg-down {
    margin-bottom: 16px;
  }
  
}
   


/* Entry Tags
/*-------------------------------------------------------*/
.entry__tags {
  margin-top: 30px;

  &-label {
    color: $heading-color;
    font-weight: 700;
    font-size: 18px;
    display: inline-block;
    margin-right: 4px;
  }
}


/* Related Posts 
/*-------------------------------------------------------*/
.related-posts {
  margin-top: 48px;

  .entry {
    margin-bottom: 24px;
  }
}


/* Comments
/*-------------------------------------------------------*/
.entry__comments {
  margin-top: 32px;
}

.comment {
  list-style: none;

  @include bp-sm-up {
    .children {
      padding-left: 8%;
    }
  }

  &-list > li:first-child > .comment-body {
    border-top: 0;
    padding-top: 0;
  }

  &-body {
    margin-bottom: 24px;
    padding-top: 24px;
    border-top: 1px solid $border-color;
  }

  &-text {
    overflow: hidden;

    p {
      margin-bottom: 10px;
    }
  }

  &-avatar {
    float: left;
    margin-right: 20px;
    display: block;
    border-radius: 50%;
    overflow: hidden;
  }

  &-author {
    font-family: $heading-font;
    font-size: 15px;
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 8px;
  }

  &-metadata {
    display: inline-block;
  }

  &-date,
  &-edit-link {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #9AA5B5;
  }

  &-edit-link {
    margin-left: 5px;
    margin-bottom: 0;
    color: $main-color;
  }

  &-reply-link {
    color: $heading-color;
    font-weight: 600;

    &:hover {
      color: $main-color;
    }
  }  
}


/* Comment Form
/*-------------------------------------------------------*/
.comment-form {
  p {
    margin: 0;
  }

  .comment-form-cookies-consent {
    margin-bottom: 24px;
  }
}

.comment-respond {
  margin-top: 40px;
}


/* Entry Navigation
/*-------------------------------------------------------*/
.entry-navigation {
  padding-top: 48px;

  &__row {
    margin: 0 -15px;
    @include display-flex;
  }

  &__col {
    padding: 0 15px;
  }

  &__item {
    @include display-flex;
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
  }

  &__item-img {
    min-width: 147px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include bp-xl-down {
      min-width: 100px;
    }

    @include bp-md-down {
      display: none;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &--left {
    float: left;
    width: 50%;
    margin-bottom: 12px;

    i {
      margin-right: 7px;
    }
  }

  &--right {
    margin-bottom: 12px;
    text-align: right;
    float: right;
    margin-left: auto;
    width: 50%;

    i {
      margin-left: 7px;
    }
  }

  &__body {
    padding: 16px 24px;
    background-color: $bg-light;
    width: 100%;
  }

  i {
    font-size: 12px;
    color: $text-color;
  }

  &__label {
    display: inline-block;
    margin-bottom: 5px;
    color: $meta-color;
    font-size: 13px;
  }

  @include bp-sm-down {
    &__row {
      display: block;
    }

    &__col {
      width: 100%;
      float: none;
    }
    
    &--right {
      text-align: left;
    }
  }
}