/*-------------------------------------------------------*/
/* WordPress Styles
/*-------------------------------------------------------*/
body.admin-bar .nav--sticky {
  top: 46px;
}

@include bp-md-up {
  body.admin-bar .nav--sticky {
    top: 32px;
  }
}

.site-title {
  font-size: 30px;
  margin-bottom: 0;
}

.gallery-caption {
  display: block;
}

img {
  height: auto;
}

.alignnone {
  margin: 8px 24px 24px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 8px auto 8px auto;
}

.alignright {
  float:right;
  margin: 8px 0 24px 24px;
}

.alignleft {
  float: left;
  margin: 8px 24px 24px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption, .gallery-caption {
  color: #666;
  font-size: 13px;
  font-size: 0.8125rem;
  font-style: italic;
  margin-bottom: 1.5em;
  max-width: 100%;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

@media (max-width: 480px) {
  .alignright {
    float: none;
    margin: 8px 0;
  }

  .alignleft {
    float: none;
    margin: 8px 0;
  }
}

/* Text meant only for screen readers. */
.screen-reader-text,
.says {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

.bypostauthor > .comment-body > .comment-meta > .comment-author .avatar {
  border: 1px solid $border-color;
  padding: 2px;
}

.widget_recent_entries ul li a {
  color: $text-color;

  &:hover {
    color: $main-color;
  }
}

.widget .recentcomments a {
  color: $text-color;
}


/*-------------------------------------------------------*/
/* Calendar
/*-------------------------------------------------------*/
#wp-calendar {
  width: 100%;

  th,
  td {
    text-align: center;
  }

  tbody td {
    line-height: 1;
  }

  &>tfoot>tr {
    border: 1px solid $border-color;
  }

  &>tfoot>tr>td {
    border: 0;
  } 
}


/*-------------------------------------------------------*/
/* Galleries
/*-------------------------------------------------------*/
.gallery-item {
	display: inline-block;
	text-align: left;
	vertical-align: top;
	margin: 0 0 1.5em;
	padding: 0 1em 0 0;
	width: 50%;
}

.gallery-columns-1 .gallery-item {
	width: 100%;
}

.gallery-columns-2 .gallery-item {
	max-width: 50%;
}

.gallery-item a,
.gallery-item a:hover,
.gallery-item a:focus,
.widget-area .gallery-item a,
.widget-area .gallery-item a:hover,
.widget-area .gallery-item a:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	background: none;
	display: inline-block;
	max-width: 100%;
}

.gallery-item a img {
	display: block;
	-webkit-transition: -webkit-filter 0.2s ease-in;
	transition: -webkit-filter 0.2s ease-in;
	transition: filter 0.2s ease-in;
	transition: filter 0.2s ease-in, -webkit-filter 0.2s ease-in;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.gallery-item a:hover img,
.gallery-item a:focus img {
	-webkit-filter: opacity(60%);
	filter: opacity(60%);
}

.gallery-caption {
	display: block;
	text-align: left;
	padding: 0 10px 0 0;
	margin-bottom: 0;
}


/* Gallery Columns
/*-------------------------------------------------------*/
@include bp-sm-up {
  .gallery-item {
    max-width: 25%;
  }

  .gallery-columns-1 .gallery-item {
    max-width: 100%;
  }

  .gallery-columns-2 .gallery-item {
    max-width: 50%;
  }

  .gallery-columns-3 .gallery-item {
    max-width: 33.33%;
  }

  .gallery-columns-4 .gallery-item {
    max-width: 25%;
  }
}

@include bp-md-up {
  .gallery-columns-5 .gallery-item {
    max-width: 20%;
  }

  .gallery-columns-6 .gallery-item {
    max-width: 16.66%;
  }

  .gallery-columns-7 .gallery-item {
    max-width: 14.28%;
  }

  .gallery-columns-8 .gallery-item {
    max-width: 12.5%;
  }

  .gallery-columns-9 .gallery-item {
    max-width: 11.11%;
  }
}

.gallery-columns-5 .gallery-caption,
.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none;
}


/*-------------------------------------------------------*/
/* Search
/*-------------------------------------------------------*/
.search-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  border: 0;
  width: 46px;
  height: 46px;
  line-height: 46px;
  background-color: transparent;
  font-size: 20px;
  color: $text-color;
}



/*-------------------------------------------------------*/
/* Password
/*-------------------------------------------------------*/
.post-password-form label > input {
  margin-bottom: 24px;
}

.post-password-form label + input {
  line-height: 41px;
  font-size: 15px;
  font-weight: 700;
  padding: 0 24px;
  font-family: $heading-font;
  border: none;
  background-color: $main-color;
  color: #fff;
  position: relative;
  top: -3px;
  width: auto;
}


/*-------------------------------------------------------*/
/* Sticky Post
/*-------------------------------------------------------*/
.post.sticky .entry__title:before {
  content: '\e801';
  font-family: "ui-icons";
  display: inline-block;
  margin-right: 12px;
  position: relative;
  top: -2px;
  font-size: 18px;
  color: $text-color;
  max-height: 20px;
}


/*-------------------------------------------------------*/
/* Article styles
/*-------------------------------------------------------*/

dt,
dd {
  padding: 10px 0;
  border-bottom: 1px solid $border-color;
}


/*-------------------------------------------------------*/
/* Comments Multipages Pagination
/*-------------------------------------------------------*/
.comment-navigation {
  margin: 20px 0;

  .nav-previous {
    float: left;
  }
  .nav-next {
    float: right;
  }
}