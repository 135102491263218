/*
Theme Name: Margin
Theme URI: https://demo.deothemes.com/margin/
Author: DeoThemes
Author URI: https://deothemes.com
Description: Margin is a clean modern WordPress theme for Marketing, SEO experts and Digital Agencies.
Version: 1.0.10
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: margin
Tags: blog, translation-ready, theme-options, custom-logo, grid-layout, footer-widgets, featured-images

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.
*/



/*-------------------------------------------------------*/
/* Table of Content

1. General
2. Typography
3. Grid
4. Layout
5. Navigation
6. Footer
7. Responsive
8. Spacings
9. Helper Classes

/*-------------------------------------------------------*/


/*-------------------------------------------------------*/
/* General
/*-------------------------------------------------------*/

html {
  font-size: 100%;
}

.clearfix {
  *zoom: 1;

  &:before, &:after {
    display: table;
    line-height: 0;
    content: "";
  }

  &:after {
    clear: both;
  }
}

::-moz-selection {
  background-color: #fdf0b2;
}

::-webkit-selection {
  background-color: #fdf0b2;
}

::selection {
  background-color: #fdf0b2;
}

:focus {
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: $body-font;
  font-size: 15px;
  line-height: 1.72;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  background: #fff;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  color: $text-color;

  img {
    border: none;
    max-width: 100%;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
}

a {
  text-decoration: none;
  color: $main-color;
  @include transition (color .1s ease-in-out);

  &:hover,
  &:focus {
    text-decoration: none;
    color: $heading-color;
  }
}

p {
  margin: 0 0 10px;
}

video {
  height: 100%;
  width: 100%;
}

hr {
  margin: 0;
  border: 0;
  border-top: 1px solid $border-color;
}


/*-------------------------------------------------------*/
/* Preloader
/*-------------------------------------------------------*/
.loader-mask {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:#fff; 
  z-index:99999;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 5px;
  width: 5em;
  height: 5em;
  margin:-25px 0 0 -25px;
  text-indent: -9999em;
  border-top: 0.5em solid $secondary-color;
  border-right: 0.5em solid rgba(245,245,245,.5);
  border-bottom: 0.5em solid rgba(245,245,245,.5);
  border-left: 0.5em solid rgba(245,245,245,.5);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

@-webkit-keyframes load8 { 0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }}
@keyframes load8 { 0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }}